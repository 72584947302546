<template>
  <div>
    <div v-show='!is_edit_page'>
    <div class='row d-flex justify-content-between align-items-center' style='margin:20px 20px 0px'>
      <div style='font-size:20px' class='d-flex'>後台管理者&nbsp;&nbsp;&nbsp;
        <el-select class="select-default " style='margin:0 10px 0 50px;width:130px;' v-model="query.search_type" :close-transition="true" clearable >
          <el-option value='fulltext' label="全欄位搜尋" />
          <el-option value='email' label="E-mail" />
          <el-option value='name' label="姓名" />
          <el-option value='company' label="分公司" />
          <el-option value='nickname' label="使用名稱"></el-option>   
        </el-select>

      
          <fg-input  
          v-model="query.search_text"
          type="text"
          style='height:30px;margin-right:10px;'
          >
          </fg-input>&nbsp;
          <button type="button" @click="getData()"  class="btn btn-fill btn-large btn-info" >搜尋</button>
      </div>

        <div class="align-right">
          <button type="button" @click="handleNew()"  class="btn btn-fill btn-large btn-info" v-if="checkScope(['user:c'])" >新增</button>
      </div>
    </div>

      <div class="row">
        <div class="col-sm-12">
          <el-table :data="tableData"  @sort-change="changeSort" sortable="custom" ref='userTable'>
            <el-table-column prop="name"
                             label="姓名">
              <div class="td-actions" slot-scope="props" >
                <a href="javascript:void(0)" @click="handleEdit(props.row)" >{{props.row.name}}</a>
              </div>
            </el-table-column>
            <el-table-column prop="email"
                             label="E-mail"
                             sortable
                             >
            </el-table-column>

            <el-table-column prop="company_id"
                             :formatter="getCompanyText"
                             label="所屬公司">
            </el-table-column>

            <el-table-column prop="title"
                             label="職稱">
            </el-table-column>
            <el-table-column prop="mobile"
                  label="聯絡手機">
            </el-table-column>
            <el-table-column prop="role_id"
                :formatter="getRoleText"
                  label="權限">
            </el-table-column>
            <el-table-column prop="is_enable" sortable
                  :formatter="getEnableText"
                  label="狀態">
            </el-table-column>
          </el-table>
        </div>
      </div>
        <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <div class="">
            <p class="card-category"></p>
          </div>
          <l-pagination class="pagination-no-border"
                      v-model="pagination.currentPage"
                      :per-page="pagination.perPage"
                      @input='handleIndexChange'
                      :total="pagination.total" />
        </div>
    </div>
    <div v-if='is_edit_page'>
    <div class='row d-flex justify-content-between align-items-center' style='margin:20px 20px 0px'>
      <div style='font-size:20px'>{{getTitle}}</div>
        <div class="align-right">
          <a href="#" @click.prevent="is_edit_page=false;editCancel()" class="" style='margin-right: 40px;font-size:14px;' >&lt;&lt;&lt;&nbsp;返回列表</a>
          <button type="button" @click="edit()" v-show='!is_edit && !is_new' class="btn btn-fill btn-info" v-if="checkScope(['member:u'])" >編輯</button>
          <button type="button" @click="editCancel()" v-show='is_edit && form.id' class="btn btn-fill btn-info" style='margin-right: 10px;'>取消</button>
          <button type="submit" @click.prevent="validate" v-show='is_edit' class="btn btn-fill btn-info" >{{getSubmitText()}}</button>
      </div>
    </div>
      <div class="col-sm-12 row" style="margin-top:40px">
        <div class="col-sm-6 " style='padding:0'>
          <div style='font-size:18px'>資料設定</div>
          <hr>
          <fg-input label="顯示名稱"
                    type="text"
                    name="nickname"
                    data-vv-as="顯示名稱"
                    class=''
                    v-model="form.nickname"   
                    v-validate="{ required: true }"
                    :error="getError('nickname')"
                    v-bind:disabled="!is_edit || form.id"
                    required>
          </fg-input>
          <fg-input label="E-mail"
                type="text"
                name="link"
                data-vv-as="E-mail"
                class=''
                v-model="form.email"   
                v-validate="{ required: true, email: true }"
                :error="getError('link')"
                v-bind:disabled="!is_edit || form.id"
                required>
            </fg-input>
          <fg-input label="姓名"
                type="text"
                name="name"
                data-vv-as="姓名"
                class=''
                v-model="form.name"   
                v-validate="{ required: true }"
                :error="getError('name')"
                v-bind:disabled="!is_edit"
                required>
            </fg-input>
          <div class=" has-label" style='margin-bottom: 1rem;'>
            <label> 所屬公司 *</label>
            <div >
            <el-select class="select-default " style='cursor: pointer;width:100%;' data-vv-as="所屬公司" placeholder="請選擇" name="company_id" v-model="form.company_id" :error="getError('company_id')"  v-validate="'required'" v-bind:disabled="!is_edit" @change="form.department_id=null" >
              <el-option v-for="i in companies" class="select-default" :value="i.id" :label="i.name" :key="i.id"  ></el-option>
            </el-select>
            </div>
            <div class='text-danger invalid-feedback' style="display:block" v-show="errors.has('company_id')">{{ errors.first('company_id') }}</div>
          </div>
          <div class=" has-label" style='margin-bottom: 1rem;'>
            <label> 部門 *</label>          
            <div >
            <el-select class="select-default " style='cursor: pointer;width:100%;' data-vv-as="部門" placeholder="請選擇" name="department_id" v-model="form.department_id" :error="getError('department_id')"  v-validate="'required'" v-bind:disabled="!is_edit" >
              <el-option v-for="i in getDepartmentOption()" class="select-default" :value="i.id" :label="i.name" :key="i.id"  ></el-option>
            </el-select>
            </div>
            <div class='text-danger invalid-feedback' style="display:block" v-show="errors.has('company_id')">{{ errors.first('department_id') }}</div>
          </div> 
          <fg-input label="職稱"
                type="text"
                name="title"
                data-vv-as="職稱"
                class=''
                v-model="form.title"   
                v-validate="{ required: true }"
                :error="getError('title')"
                v-bind:disabled="!is_edit"
                required>
            </fg-input>
          <fg-input label="電話"
                type="text"
                name="tel"
                data-vv-as="電話"
                class=''
                v-model="form.tel"   
                v-validate="{ required: true }"
                :error="getError('tel')"
                v-bind:disabled="!is_edit"
                required>
            </fg-input>
          <fg-input label="聯絡手機"
                type="text"
                name="mobile"
                data-vv-as="聯絡手機"
                class=''
                v-model="form.mobile"   
                v-validate="{ required: true }"
                :error="getError('mobile')"
                v-bind:disabled="!is_edit"
                required>
            </fg-input>
        </div>
        <div class="col-sm-6">
          <div style='font-size:18px'>密碼設定</div>
          <hr>
          <fg-input label="密碼設定"
                type="password"
                value='***************'
                disabled
                v-show='!is_edit'
                style='margin-bottom: 209px;'
                required>
          </fg-input>
          <fg-input label="密碼設定"
                type="password"
                name="password"
                data-vv-as="密碼設定"
                class=''
                v-model="form.password"   
                v-validate="getPasswordRule"
                ref="password"
                :error="getError('password')"
                v-show='is_edit'
                required>
          </fg-input>

          <fg-input label="再次輸入密碼"
                type="password"
                name="confirm_password"
                data-vv-as="再次輸入密碼"
                class=''
                v-model="form.confirm_password"   
                v-validate="{ confirmed: 'password' }"
                :error="getError('confirm_password')"
                v-show='is_edit'
                required>
          </fg-input>
          <div class='row d-flex justify-content-between align-items-center' style='margin:125px 0px 0px'>
          <div style='font-size:18px;'>權限設定</div>
          <a href="#" @click.prevent="handleDelete(form.id)" v-show="form.id" class="" style='position: absoutle;right: 40px;font-size:14px;border-bottom: 1px solid currentColor;' v-if="checkScope(['user:d'])"  >刪除管理者</a>
          </div>
          <hr>
          <div class=" has-label" style='margin-bottom: 1rem;'>
            <label> 角色 *</label>
            <div v-show='!is_edit' > {{ getRoleText(form) }} </div>
            <div  v-show='is_edit'>
            <el-select class="select-default " style='cursor: pointer;width:100%;' data-vv-as="角色" placeholder="請選擇" name="role_id" v-model="form.role_id" :error="getError('role_id')"  v-validate="'required'" v-bind:disabled="!is_edit" >
              <el-option v-for="i in getRoleOption" class="select-default" :value="i.id" :label="i.text" :key="i.id"  ></el-option>
            </el-select>
            </div>
            <div class='text-danger invalid-feedback' style="display:block" v-show="errors.has('role_id')">{{ errors.first('role_id') }}</div>
          </div>
         
          <div class="form-group has-label">
            <label> 權限狀態</label>          
            <div>
               <div v-show='!is_edit'>{{ getEnableText(form)}}</div>
              <l-switch v-show='is_edit' v-model="form.is_enable" on-text="ON" off-text="OFF" v-bind:disabled="!is_edit" ></l-switch>
            </div>
          </div>
        </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
 /* eslint-disable */ 
  import { Dialog, Table, TableColumn, MessageBox, Select, Option } from 'element-ui'
  import { Pagination as LPagination, ImageUpload,  Switch as LSwitch } from 'src/components/index'
  import lib from '../../../lib'
  import tools from '../../../tools'
  import swal from 'sweetalert2/dist/sweetalert2.js'

  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Dialog.name]: Dialog,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      LSwitch,
      LPagination,
      ImageUpload,

    },
    data () {
      return {
        pagination: {
          perPage: 20,
          currentPage: 1,
          total: 0
        },
        form:{
          id: null,
          image: null,
          title: null,
          content: null,

        },
        searchForm:{
        },
        roles:[
          {id: 2, text: 'Admin'},
          {id: 3, text: 'Manager'},
          {id: 4, text: 'Assistant'}
        ],
        query: {
          search_type: null,
          search_text: ''
        },
        is_edit_page: false,
        is_edit: false,
        is_new: false,
        tableData: [],
        companies: []
      }
    },
    async mounted () {
      await this.resetPage()
    },
    watch:{
    },
    computed: {
      getTitle (){
        return (!this.form.id)?'新增後台管理者':'編輯後台管理者'
      },
      getPasswordRule(){
        let rule = {}
        if (!this.form.id){
          rule.required = true
        }
        rule.regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
        return rule
      },
      getRoleOption(){
        let aa = new Date()
        let user = lib.getUser()
        let role_id = user.role_id
        return this.roles.filter(el => el.id > role_id)
      },
    },
    methods: {
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      getEnableText(row){
        var is_enable = row.is_enable
        return (is_enable)?'ON':'OFF'
      },
      getSubmitText(){
        return (!this.form.id)?'新增管理者':'儲存管理者'
      },
      getDepartmentOption(){
        let company = this.companies.find((el) => this.form.company_id == el.id)
        return (company)?(company.departments):[]
      },
      validate () {
        var _this = this
        this.$validator.validateAll().then(isValid => {
          console.log( this.$validator)
          if (!isValid) return;
          if (!this.form.id){
            _this.create()
          } else{
            _this.update()
          }
        })
      },
      handleImageUpload(result, data){
        if (result){
          this.form.image = data
        }else{
          swal('上傳失敗', data, 'error')
        }
      },
      edit (){
        this.is_edit = true
      },
      editCancel (){
        this.is_edit = false
        if (this.origin) this.form = JSON.parse(JSON.stringify(this.origin))
      },
      getCompanyText(row, idx){
        var id = row.company_id
        for (var i=0 ; i < this.companies.length ; i++){
          if (this.companies[i]['id'] == id) {
            return this.companies[i]['name']
          }
        }
        return ''
      },
      getRoleText(row, idx){
        var id = row.role_id
        for (var i=0 ; i < this.roles.length ; i++){
          if (this.roles[i]['id'] == id) {
            return this.roles[i]['text']
          }
        }
        return ''
      },
      async resetPage(){
          await this.getCompanies()
          await this.getData()
      },
      async changeSort (val) {
        let orderBy = ''
        if (val.order && val.prop){
          orderBy = val.prop
          if (val.order == 'descending') orderBy +=',desc'
        }
        this.query.orderBy = orderBy
        await this.getData();
      },
      async handleIndexChange() {
        await this.getData()
      },
      async handleNew () {
        await this.clearForm()
        this.is_edit_page = true
        this.is_edit = true
        this.form = {}
        this.$validator.errors.clear()
      },
      async handleEdit (row) {
        await this.clearForm()
        this.is_edit_page = true
        this.$validator.errors.clear()
        this.origin = JSON.parse(JSON.stringify(row))
        this.form = JSON.parse(JSON.stringify(row))
      },
      async getData () {
        let query = {}
        query.page =  this.pagination.currentPage
        query.limit = this.pagination.perPage
        query.orderby = this.query.orderBy
        if (this.query.search_type && this.query.search_text){
          query.search_type = this.query.search_type
          query.search_text = encodeURIComponent(this.query.search_text.toString())
        }
  
        var result = await lib.getUserList(query)
        if (result.code == 0){
          var data = result.data
          this.tableData = data
          this.pagination.total = result.pages.total
        }
      },
      async getCompanies () {
        var result = await lib.getCompanyList({page: 1, limit: 100})
        if (result.code == 0){
          var data = result.data
          this.companies = data
        }
      },
      async clearForm(){
        var _this = this
        return new Promise(function(resolve, reject){  
            _this.form.id = ''
          resolve()
        })
      },
      async create(){
        var result = await lib.createUser(this.form);
        if (result.code == 0){
          await this.getData()
          this.is_edit = false
          this.is_edit_page = false
          swal('新增成功', '', 'success')
        }else {
          let msg = '新增失敗'
          if (result.code == 2) msg = '帳號已存在'
          swal(msg, JSON.stringify(result.data), 'error')
        }
      },
      async update(){
        var result = await lib.updateUser(this.form.id, this.form);
        if (result.code == 0){
          await this.getData()
          this.is_edit = false
          this.is_edit_page = false
          swal('已更新', '', 'success')
        }else {
          swal('更新失敗', '', 'error')
        }
      },
      async handleDelete(id, porp){
        var confirm = await tools.confirmDialog("請確認要刪除?");
          if (confirm){
            var result = await lib.deleteUser(id)
            if (result.code == 0){
              this.pagination.currentPage = 1
              this.is_edit = false
              this.is_edit_page = false
              await this.getData()
              swal('已刪除', '', 'success')
            }else{
              swal('刪除失敗', '', 'error')
            }
        }
      }
    }
  }
</script>
<style>
.toolsBar{
  justify-content: space-between;
  display: flex;
}

.leftTools{
  display: flex;
}

.leftTools > *{
  padding-right: 10px;
}
</style>
