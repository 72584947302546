<template>
  <div>
      <div class="row">
         
         <h2 class="col-md-12 text-center">This is overview page</h2>

        <!-- <div class="col-md-6">
          <card class="card-tasks" title="系統基本資料" subTitle="站台管理者" v-if="userIsSuper()">
            <div v-for="(i, idx) in tableData" v-bind:key='idx'>
              {{getHotelText(i,idx)}}: {{i.name}}
            </div>
            <el-table :data="tableData">
              <el-table-column prop="hotel_id"
                              :formatter="getHotelText"  
                              label="負責飯店">
              </el-table-column>
              <el-table-column prop="name"
                              label="姓名">
              </el-table-column>  
            </el-table>
          </card>


          <card class="card-tasks" title="設定數量" subTitle="" v-if="userIsAdmin()">
            <el-table :data="managerData">
              <el-table-column prop="text"
                              label="">
              </el-table-column>
              <el-table-column prop="count"
                              label="">
              </el-table-column>  
            </el-table>
            <div class="stats" slot="footer">
              <i class="fa fa-history"></i> Updated 3 minutes ago
            </div>
          </card>
        </div> -->
      </div>
  </div>
</template>
<script>
  import Vue from "vue"
  import { Dialog, Table, TableColumn, MessageBox, Select, Option } from 'element-ui'
  import lib from '../../../lib'

  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Dialog.name]: Dialog,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    data () {
      return {
          hotels: [],
          tableData: [],
          tvChannels: [],
          appUsers:[],
          managerData: []
      }
    },
   async mounted () {
     if (this.userIsSuper()){
        // await this.getHotelData()
        // await this.getUsers()
     }

     if (this.userIsAdmin()){
        // await this.getTvChannels()
        // await this.getAppUsers()
        // this.managerData = [
        //   { text: '代碼設定數量', count: this.appUsers.length },
        //   { text: 'TＶ頻道設定數量', count: this.tvChannels.length }
        // ]
     }
    },
    methods: {
      userIsSuper() {
        return lib.userIsSuper()
      },
      userIsAdmin() {
        return lib.userIsAdmin()
      },
      // getUserRole() {
      //   var user = lib.getUser()
      //   return user.role
      // },
      getHotelText(row, idx){
        var id = row.hotel_id
        for (var i=0 ; i < this.hotels.length ; i++){
          if (this.hotels[i]['id'] == id) {
            return this.hotels[i]['name']
          }
        }
        return ''
      },
      // async getHotelData () {
      //   var result = await lib.getHotelList()
      //   if (result.success){
      //     var data = result.data
      //     this.hotels = data
      //   }
      // },
      // async getUsers () {
      //   var result = await lib.getUserList()
      //   if (result.success){
      //     var data = result.data
      //     this.tableData = data
      //   }
      // },

      // async getTvChannels () {
      //   var result = await lib.getTvChannelList()
      //   if (result.success){
      //     var data = result.data
      //     this.tvChannels = data
      //   }
      // },

      // async getAppUsers () {
      //   var result = await lib.getAppUserList()
      //   if (result.success){
      //     var data = result.data
      //     this.appUsers = data
      //   }
      // },
    }
  }
</script>
<style>

</style>
